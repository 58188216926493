import WebFormRepository from '@/shared/http/repositories/socialProject/web-form'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import WebFormQuestion from '@/views/tools/web-form/components/webFormQuestion/webFormQuestion.vue'
import QuestionModel from '@/shared/models/formWebQuestion'
import { IsSuccessCode } from '@/shared/utils/API'
import {toast, loading } from '@/shared/utils/UIHelper'

export default {
  name: 'NovoWebFormQuestion',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [WebFormQuestion.name]: WebFormQuestion
  },

  data: () => ({
    title: 'Questão',
    formWebQuestion: new QuestionModel(),
    isNew: true
  }),

  provide() {
    return { parentValidator: this.$validator }
  },

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Questões',
            href: this.$router.resolve({ name: 'web-form' }).href
          },
          {
            text: 'Nova questão do formulário',
            active: true
          }
        ]
      }
    }
  },

  methods: {
    create(formWebQuestion) {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }

        
        loading.push()
        this.formWebQuestion.formwebid = this.$route.params.formwebid
        WebFormRepository.CreateQuestion(formWebQuestion)
          .then(res => {
            if (!IsSuccessCode(res)) return Promise.reject()
  
            loading.pop()
            toast.success('Questão criada com sucesso', 'NOVA QUESTÃO')
            Promise.resolve()
            return this.$router.replace({ name: 'web-form' })
          })
          .catch(() => {
            loading.pop()
            toast.error('Erro ao criar questão', 'ERRO')
          })
      })
    }
  }
}
